/* ==========================================================================
   Opacity
   ========================================================================== */

$opacity: 10;

@for $i from 0 through $opacity {
    .opacity-#{$i} {
        opacity: #{($i / 10)};
    }

    .opacity-#{$i}-hover {
        transition: opacity .35s ease;

        &:hover {
            opacity: #{($i / 10)};
        }
    }
}