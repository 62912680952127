/* ==========================================================================
   Position
   ========================================================================== */

$shifts: 10;

@for $i from -10 through $shifts {
    .top-#{$i} {
        top: #{$i}px;
    }
    .right-#{$i} {
        top: #{$i}px;
    }
    .bottom-#{$i} {
        top: #{$i}px;
    }
    .left-#{$i} {
        top: #{$i}px;
    }
}